const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const additionalDetailsContainerTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (capitalize, features, isModal) {
jade_mixins["lineUpPanel"] = jade_interp = function(type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( !isModal || features[type].length > 0)
{
buf.push("<div id=\"section-details\"" + (jade.attr("data-field", type + 'Details', true, false)) + " class=\"panel panel-styled js-details-panel\"><div class=\"panel-header panel-header--justified\"><h2>" + (jade.escape(null == (jade_interp = (type.charAt(0).toUpperCase() + type.slice(1)) + ' Details') ? "" : jade_interp)) + "</h2><div class=\"control-group\"><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-clear-items\">Clear Items</button>\n</div></div><div class=\"panel-content\"><div" + (jade.attr("id", 'field--' + type + 'details', true, false)) + " class=\"form-row is-hidden\"><div class=\"js-error\"></div><table" + (jade.cls(['js-' + type + '-details'], [true])) + "></table></div><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-add-item\">Add Item</button><p></p></div></div>");
}
};
jade_mixins["tickBox"] = jade_interp = function(feature){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label class=\"form-field form-row-description\"><input type=\"checkbox\" name=\"importField\"" + (jade.attr("value", feature, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = capitalize(feature)) ? "" : jade_interp)) + "</span></label>");
};
if ( !isModal)
{
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Import Features</h2></div><div class=\"panel-content\"><div class=\"form-row\"><label><span class=\"form-label-text\">Vehicle</span><div class=\"js-vehicle form-field\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The vehicle to import features from</p></div></div><div class=\"form-row form-row-boolean\"><label><span class=\"form-label-text\">Features</span>");
jade_mixins["tickBox"]('safety');
jade_mixins["tickBox"]('interior');
jade_mixins["tickBox"]('exterior');
jade_mixins["tickBox"]('technical');
buf.push("</label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The features to import from the vehicle</p></div></div><div class=\"form-row form-row-actions\"><input value=\"Import Features\" class=\"btn btn--success js-features-add\"/></div></div></div>");
}
jade_mixins["lineUpPanel"]('safety');
jade_mixins["lineUpPanel"]('interior');
jade_mixins["lineUpPanel"]('exterior');
jade_mixins["lineUpPanel"]('technical');}.call(this,"capitalize" in locals_for_with?locals_for_with.capitalize:typeof capitalize!=="undefined"?capitalize:undefined,"features" in locals_for_with?locals_for_with.features:typeof features!=="undefined"?features:undefined,"isModal" in locals_for_with?locals_for_with.isModal:typeof isModal!=="undefined"?isModal:undefined));;return buf.join("");
}
const detailsItemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (index, label, type, value) {
buf.push("<tr" + (jade.attr("data-type", type, true, false)) + (jade.cls(['js-additional-item','js-' + type], [null,true])) + "><td><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></td>");
const isTechnical = type === 'technical'
buf.push("<td" + (jade.attr("id", 'field--item-label-' + index, true, false)) + (jade.attr("data-field", 'item-label-' + index, true, false)) + " class=\"form-row\"><input type=\"text\" name=\"label\"" + (jade.attr("value", label, true, false)) + (jade.attr("data-index", index, true, false)) + " placeholder=\"Label\"" + (jade.attr("disabled", !isTechnical, true, false)) + (jade.cls(['control','control--text',!isTechnical?'grey-out-label': ''], [null,null,true])) + "/></td><td" + (jade.attr("id", 'field--item-value-' + index, true, false)) + (jade.attr("data-field", 'item-value-' + index, true, false)) + " class=\"form-row\"><input type=\"text\" name=\"value\"" + (jade.attr("value", value, true, false)) + (jade.attr("data-index", index, true, false)) + " placeholder=\"Value\" class=\"control control--text\"/></td><td" + (jade.attr("id", 'field--item-remove-' + index, true, false)) + (jade.attr("data-field", 'item-remove-' + index, true, false)) + " class=\"form-row\"><div class=\"list-item-actions\"><div class=\"btn-group\"><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"btn js-remove-item\"><i class=\"icon icon--cross--dark\"></i></a></div></div></td></tr>");}.call(this,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined));;return buf.join("");
}
const capitalize = require('capitalize')
const BaseSelect = require('../../base/views/select')
const StandardDataModal = require('../../standard-data/views/feature-picker')

class AdditionalDetailsView extends View {
  constructor(serviceLocator, model, isModal) {
    super(serviceLocator)
    this.model = model
    this.isModal = isModal
    this.itemCount = {
      safety: 0,
      interior: 0,
      exterior: 0,
      technical: 0
    }

    this.propertyNames = ['safety', 'interior', 'exterior', 'technical']
  }

  render() {
    this.$el.append(
      additionalDetailsContainerTemplate({
        capitalize,
        isModal: this.isModal,
        features: this.model.toJSON()
      })
    )

    this.propertyNames.map(propName => this.renderItems(propName))

    this.$el
      .find('.js-clear-items')
      .on('click', this.handleClearItems.bind(this))

    this.$el.find('.js-add-item').on('click', this.handleAddNewItem.bind(this))
    this.$el
      .find('.js-features-add')
      .on('click', this.handleImportFeatures.bind(this))
    this.$el.on('click', '.js-add-standard', this.chooseFeatures.bind(this))

    this.renderVehicleSelect()
    return this
  }

  chooseFeatures(e) {
    const type = $(e.currentTarget).data('type')

    const modal = new StandardDataModal(this.serviceLocator, type).render()

    modal.on('add', () => {
      modal.selectedCollection.forEach(model => {
        const items = this.model.get(type)
        items.push({ label: model.get('label'), value: model.get('value') })
        this.model.set(type, items)
        this.addItem(model.get('label'), model.get('value'), type, true)
      })
    })
  }

  renderVehicleSelect() {
    const vehicleSelect = new BaseSelect(this.serviceLocator, {
      service: 'usedVehicleService',
      singular: 'vehicle',
      plural: 'vehicles',
      limitToOneValue: true,
      textProperty: data =>
        data && `${data.brand} ${data.derivative} - ${data.registration}`,
      sortProperty: 'registration'
    })

    this.$el.find('.js-vehicle').append(vehicleSelect.render().$el)
    vehicleSelect.on('change', vehicle => {
      this.selectedVehicle = vehicle
    })
    this.attachView(vehicleSelect)
  }

  handleImportFeatures() {
    const items = this.$el
      .find('input[name="importField"]:checked')
      .toArray()
      .map(box => box.value)

    if (items.length <= 0) return

    this.serviceLocator.usedVehicleService.read(
      this.selectedVehicle,
      (err, vehicle) => {
        if (err) return alert('Could not read vehicle')

        items.forEach(item => {
          vehicle[item].forEach(({ label, value }) => {
            const values = this.model.get(item)
            values.push({ label, value })
            this.model.set(item, values)

            this.addItem(label, value, item, true)
          })
        })
      }
    )
  }

  handleRemoveItem(e) {
    e.preventDefault()
    const index = $(e.currentTarget).data('index')
    const type = $(e.currentTarget).data('type')
    const items = this.model.get(type)
    items.splice(index, 1)
    this.model.set(type, items)
    this.renderItems(type)
  }

  handleClearItems(e) {
    e.preventDefault()
    const detailsType = $(e.currentTarget).data('type')
    this.clearItems(detailsType)
  }

  renderItems(type) {
    this.itemCount[type] = 0

    const item = '.js-' + type + '-details'
    this.$el.find(item).empty()
    const currentItems = this.model.get(type) || []

    for (let i = 0; i < currentItems.length; i++) {
      const item = currentItems[i]
      const itemLabel = item ? item.label : null
      const itemValue = item ? item.value : null
      this.addItem(itemLabel, itemValue, type)
    }

    this.$el
      .find(item)
      .sortable({
        handle: '.js-sort-handle',
        forcePlaceholderSize: 'true',
        cursor: 'move',
        cursorAt: { left: 30, top: 30 },
        tolerance: 'pointer'
      })
      .off('sortupdate')
      .on('sortupdate', () => {
        const items = []

        this.$el
          .find(`.js-additional-item[data-type='${type}']`)
          .map((index, el) => {
            const label = $(el)
              .find(`input[name='label']`)
              .val()
            const value = $(el)
              .find(`input[name='value']`)
              .val()

            items.push({ label, value })
          })

        this.model.set(type, items)
        this.renderItems(type)
      })
  }

  addItem(itemLabel, itemValue, type, newItem = false) {
    const index = newItem ? 0 : this.itemCount[type]++

    if (newItem) {
      this.$el.find('.js-' + type + '-details').prepend(
        detailsItemTemplate({
          label: itemLabel,
          value: itemValue,
          index,
          type
        })
      )
    } else {
      this.$el.find('.js-' + type + '-details').append(
        detailsItemTemplate({
          label: itemLabel,
          value: itemValue,
          index,
          type
        })
      )
    }

    this.$el.find('.js-' + type + '-details').on('change', e => {
      const index = e.target.dataset.index
      const key = e.target.name
      const values = this.model.get(type)
      const currentValue = values[index]
      const itemObject = {}
      itemObject[key] = e.target.value

      values[index] = Object.assign({}, currentValue, itemObject)

      this.model.set(type, values)
    })

    this.$el
      .find(`.js-${type} #field--item-remove-` + index + ` .js-remove-item`)
      .on('click', e => {
        this.handleRemoveItem(e)
      })

    if (newItem) {
      this.processItems(type)
      this.renderItems(type)
    }
  }

  handleAddNewItem(e) {
    e.preventDefault()
    const $clickedEl = $(e.currentTarget)
    const type = $clickedEl.data('type')
    const items = this.model.get(type)
    items.push({ label: '', value: '' })
    this.model.set(type, items)
    this.addItem(null, null, type)
  }

  processItems(type) {
    const items = []
    this.$el
      .find(`.js-additional-item[data-type='${type}']`)
      .map((index, el) => {
        const label = $(el)
          .find(`input[name='label']`)
          .val()
        const value = $(el)
          .find(`input[name='value']`)
          .val()

        items.push({ label, value })
      })

    this.model.set(type, items)
  }

  clearItems(type) {
    this.model.set(type, [])
    this.renderItems(type)
  }
}

module.exports = AdditionalDetailsView
