const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowSelection, model) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"></div><div class=\"control-group\"><div class=\"btn-group\">");
if ( allowSelection)
{
buf.push("<input type=\"checkbox\" name=\"selected\"" + (jade.attr("checked", model.selected, true, false)) + " class=\"control--boolean js-select\"/>");
}
buf.push("<a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a" + (jade.attr("href", model.buildOriginalUrl(), true, false)) + " target=\"_blank\" rel=\"noopener\">View Original</a></li><li class=\"divider\"></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div></div><div class=\"list-item-content expander-content\"><div class=\"image-select-preview js-image-select-preview\"><a class=\"js-edit\"><img" + (jade.attr("src", model.buildPreviewUrl(150, 150), true, false)) + " data-toggle=\"tooltip\"" + (jade.attr("title", model.get('name'), true, false)) + " class=\"js-preview-image\"/></a></div><div class=\"image-select-controls js-image-select-controls form-row js-contexts\"></div></div></div></div>");}.call(this,"allowSelection" in locals_for_with?locals_for_with.allowSelection:typeof allowSelection!=="undefined"?allowSelection:undefined,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
}
const processingTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"asset-status js-asset-status\"><div class=\"asset-status__inner\"><i class=\"icon-custom icon-custom--spinner\"></i><div class=\"asset-status__title\">Processing Image</div><div class=\"asset-status__content\"><button class=\"btn btn--small js-crop-refresh\">Refresh</button></div></div></div>");;return buf.join("");
}
const failedTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"asset-status asset-status--error js-asset-status\"><div class=\"asset-status__inner\"><i class=\"icon icon--warning\"></i><div class=\"asset-status__title\">Image Failed</div><div class=\"asset-status__content\">Please remove this image and try again</div></div></div>");;return buf.join("");
}
const warningTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"asset-status asset-status--error js-asset-status\"><div class=\"asset-status__inner\"><i class=\"icon icon--warning\"></i><div class=\"asset-status__title\">Corrupt Crops</div><div class=\"asset-status__content\"><button class=\"btn btn--small js-crop-refresh\">Refresh</button></div></div></div>");;return buf.join("");
}
const BaseItemView = require('../../../widget/views/item/edit-base')
const debug = require('../../../../../admin/source/js/lib/debug')(
  'edit base widget item view'
)
const CropSelectControls = require('../../templates/widget-templates/components/context-select-controls/view')
const config = window.config
const extend = require('lodash.assign')
const filter = require('lodash.filter')

module.exports = BaseItemView.extend({
  template,
  events: extend({}, BaseItemView.prototype.events, {
    'click .js-crop': 'handleCrop',
    'click .js-select': 'toggleSelect',
    'click .js-blur': 'handleBlur',
    'click .js-reset': 'handleReset',
    'click .js-dvi': 'handleDvi',
    'click .js-crop-refresh': 'refreshCrops'
  }),

  initialize(options) {
    this.options = options
    if (!this.options.contexts)
      throw new Error('This widget requires a list of contexts')

    this.listenTo(this.model, 'retrievingCrops', () => {
      this.displayImageProcessing()
    })

    this.listenTo(this.model, 'cropsFailed', () => {
      this.displayImageError()
    })

    this.listenTo(this.options.widgetArea, 'updateContextSelections', () => {
      this.updateContextSelections()
    })

    this.$el.on('click input.js-image-contexts', () => {
      this.options.widgetArea.trigger('updateContextSelections')
    })

    this.extraProperties = Object.assign({}, this.extraProperties, {
      allowSelection: options.allowSelection
    })

    BaseItemView.prototype.initialize.apply(this, arguments)

    this.listenTo(this.model, 'select', this.select)
    this.listenTo(this.model, 'deSelect', this.deSelect)

    // Prevent edit overlay being fired when adding images.
    this.off('init')
    this.on('init', () => {
      this.updateContextSelections()
    })
  },

  handleEdit(e, init) {
    init = init || false
    debug('edit', this.model.get('id'))
    if (this.editView !== null) {
      this.factory.editView = this.editView
    }

    // Return early if this widget does
    // not have an edit view e.g. static
    if (!this.factory.editView) return

    const EditView = this.factory.editView
    const editView = new EditView({
      model: this.model,
      serviceLocator: this.options.serviceLocator,
      factoryOptions: this.factory.options
    })
    this.showModal(editView, 'medium-wide', init)
  },

  handleBlur() {
    this.model.blurImage()
  },

  handleReset() {
    this.model.resetToOriginal()
  },

  handleDvi() {
    this.model.getDviImage()
  },

  toggleSelect(e) {
    const isChecked = $(e.target).is(':checked')
    this.model.trigger(isChecked ? 'select' : 'deSelect')
  },

  select() {
    this.$('.js-select')[0].checked = true
  },

  deSelect() {
    this.$('.js-select')[0].checked = false
  },

  displayImageProcessing() {
    this.removeImageStatus()
    this.$('.js-image-select-preview').prepend(processingTemplate())
  },

  displayImageError() {
    this.removeImageStatus()
    this.$('.js-image-select-preview').prepend(failedTemplate())
  },

  removeImageStatus() {
    this.$('.js-asset-status').remove()
  },

  refreshCrops() {
    this.removeImageStatus()
    var newCrops = filter(
      this.options.crops,
      (value, index) => {
        var crops = this.model.get('crops')
        var comparator = crops && this.model.get('crops')[index]
        return (
          typeof crops === 'undefined' ||
          !comparator ||
          value.name !== comparator.name
        )
      },
      this
    )

    // not checking whether there's new crops will flash an error
    if (newCrops.length) {
      this.model.setDefaultCrops(newCrops, true)
    }

    this.model.setCrops()
  },

  displayImageWarning() {
    this.$('.js-image-select-preview').prepend(warningTemplate())
    this.$('.js-crop')
      .addClass('disabled')
      .attr('disabled', true)
  },

  render() {
    var result = BaseItemView.prototype.render.call(this)
    this.cropsChecked = false

    this.initCropSelectionControls()

    debug('Adding images', this.options.widgetArea)

    // Check all inputs on the first image added to the widget area.
    if (!this.hasContextsSelected()) {
      if (config.optionalImageContextsDeselectedByDefault) {
        this.$(':input.context-required.js-image-contexts').attr(
          'checked',
          'checked'
        )
      } else {
        this.$(':input.js-image-contexts').attr('checked', 'checked')
      }
    }

    this.checkImageIntegrety()

    return result
  },

  checkImageIntegrety() {
    var cropsValid = true

    Object.keys(this.options.crops).forEach(key => {
      if (typeof this.model.get('crops') === 'undefined') {
        cropsValid = false
        return
      }
      var crop = this.model.get('crops')[key]
      if (typeof crop === 'undefined' || typeof crop.src === 'undefined') {
        cropsValid = false
      }
    }, this)

    if (!cropsValid) {
      if (!this.cropsChecked) {
        this.displayImageProcessing()
        this.refreshCrops()
        this.cropsChecked = true
      } else {
        this.displayImageWarning()
      }
    } else {
      this.$('.js-crop')
        .removeClass('disabled')
        .removeAttr('disabled', true)
    }
  },

  updateContextSelections() {
    var selectedContexts = ['All']
    this.$('.js-image-contexts:checked').each((i, context) => {
      selectedContexts.push($(context).attr('name'))
    })

    this.model.set('selectedContexts', selectedContexts)

    this.options.widgetArea.model.trigger('update')
  },

  initCropSelectionControls() {
    var contexts = this.options.contexts

    debug('Adding crop selection controls', contexts)

    contexts.forEach(context => {
      const contextModel = new window.Backbone.Model(context)
      const contextView = new CropSelectControls({
        model: contextModel,
        checked: this.model.get('selectedContexts').indexOf(context.name) !== -1
      })
      this.$('.js-image-select-controls').append(contextView.$el)
    })
  },

  handleCrop() {
    debug('crop', this.model.get('id'))
    const CropView = this.factory.cropView
    const cropView = new CropView({
      model: this.model,
      crops: this.options.crops
    })

    this.showModal(cropView, 'medium')
  },

  hasContextsSelected() {
    return this.options.widgetArea.model.widgets.length > 1
  }
})
