const compileJade = require('browjadify-compile')
const join = require('path').join
const modal = require('modal')

const AccountSelect = require('../../account/views/account-select')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (menu, undefined) {
// iterate menu
;(function(){
  var $$obj = menu;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var section = $$obj[$index];

if ( section.items.length)
{
buf.push("<div class=\"main-navigation__title\">" + (jade.escape(null == (jade_interp = section.title) ? "" : jade_interp)) + "</div><ul>");
// iterate section.items
;(function(){
  var $$obj = section.items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var section = $$obj[$index];

if ( section.items.length)
{
buf.push("<div class=\"main-navigation__title\">" + (jade.escape(null == (jade_interp = section.title) ? "" : jade_interp)) + "</div><ul>");
// iterate section.items
;(function(){
  var $$obj = section.items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var menuItem = $$obj[$index];

buf.push("<li><a" + (jade.attr("href", '/' + menuItem.route, true, false)) + (jade.attr("data-route", menuItem.route, true, false)) + ">" + (jade.escape(null == (jade_interp = menuItem.name) ? "" : jade_interp)) + "</a></li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
    }

  }
}).call(this);
}.call(this,"menu" in locals_for_with?locals_for_with.menu:typeof menu!=="undefined"?menu:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const {
  setTenant,
  getTenant
} = require('../../account/lib/account-switch-handler')

const config = window.config

module.exports = window.Backbone.View.extend({
  events: {
    'click [data-route]': 'handleRoute',
    'change .js-account-switcher': 'handleAccountSwitch'
  },

  initialize: function(options) {
    this.options = options
    this.tenant = getTenant()
    this.setFallbackAccount = !this.tenant

    this.accountSelectView = new AccountSelect(
      this.options.serviceLocator,
      this.tenant,
      this.setFallbackAccount
    )
    this.accountSelectView.notSetLabel = false
    this.accountSelectView.on('change', accountId => {
      this.handleAccountSwitch(accountId)
    })
  },

  handleAccountSwitch: function(accountId) {
    const beforeChange = this.accountSelectView.previousValue
    const select =
      document && document.querySelector('.js-account-switcher select')

    modal({
      title: 'Account change',
      clickOutsideToClose: false,
      content:
        'Are you sure you want to switch the account? All unsaved changes will be lost!',
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
        { text: 'Confirm', event: 'confirm', className: 'btn btn--action' }
      ]
    })
      .on(
        'cancel',
        function() {
          if (!select) return
          select.value = beforeChange
        }.bind(this)
      )
      .on(
        'confirm',
        function() {
          setTenant(accountId)
          window.location.reload()
        }.bind(this)
      )
  },

  handleRoute: function(e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if (e.which === 2 || e.metaKey) return
    e.preventDefault()
    this.trigger('route', $(e.target).attr('data-route'))
  },

  render: function() {
    this.$el.append(
      template({
        menu: this.options.menu,
        config: config,
        allowed: this.options.serviceLocator.allowed
      })
    )

    this.accountSelectView
      .populate()
      .render()
      .$el.appendTo(document && document.querySelector('.js-account-switcher'))
  }
})
