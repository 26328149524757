module.exports = AccountSelectView
const View = require('ventnor')
const { setTenant } = require('../../account/lib/account-switch-handler')

function AccountSelectView(serviceLocator, value, setFallbackAccount) {
  View.apply(this, arguments)
  this.$el = $('<select/>')
  this.$el.attr('name', 'account')
  this.$el.addClass('control control--choice')
  this.value = value
  this.previousValue = value
  this.setFallbackAccount = setFallbackAccount
  this.$el.on(
    'click',
    function() {
      this.previousValue = this.$el.val()
    }.bind(this)
  )
  this.$el.on(
    'change',
    function() {
      this.emit('change', this.$el.val())
    }.bind(this)
  )
  this.notSetLabel = '-- Select an account --'
}

AccountSelectView.prototype = Object.create(View.prototype)

AccountSelectView.prototype.render = function() {
  // prevents "all accounts" selection in the account switcher
  if (!this.notSetLabel) return this
  this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
  return this
}

AccountSelectView.prototype.populate = function() {
  this.serviceLocator.accountService.find(
    '',
    {},
    ['name'],
    {},
    { unrestricted: true },
    function(err, res) {
      if (err) return this.serviceLocator.logger.error(err)

      res.results.forEach(
        function(account, i) {
          // For admins with no specific account set.
          // This ensures the tenant is set to the very first
          // account from the selector list. For users with
          // pre-set account, this will be ignored, as
          // we would have already set the tenant id on login.
          if (this.setFallbackAccount && i === 0) {
            setTenant(account._id)
          }

          this.$el.append(
            $('<option/>', {
              text: account.name,
              value: account._id,
              selected: this.value === account._id
            })
          )
        }.bind(this)
      )
    }.bind(this)
  )
  return this
}
