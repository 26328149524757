const View = require('ventnor')
const Pagination = require('./pagination')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\">");
if ( (allowed(name.resource, 'delete')))
{
buf.push("<div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-delete\">Delete Selected</button></div>");
}
if ( (allowed(name.resource, 'create')))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New " + (jade.escape((jade_interp = name.singular) == null ? '' : jade_interp)) + "</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const Collection = require('chale')
const modal = require('modal')
const ListItemView = require('./list-item')

function ListView(serviceLocator, collection, paginationModel) {
  View.apply(this, arguments)
  Object.assign(this, Pagination.prototype)
  Pagination.call(this, paginationModel)

  this.collection = collection
  this.createFilterView()
  this.selectedCollection = new Collection(serviceLocator)

  this.listenTo(collection, 'add', this.addListItem.bind(this))
  this.listenTo(collection, 'remove', this.removeListItem.bind(this))
  this.listenTo(collection, 'reset', this.resetListItems.bind(this))
  this.listenTo(collection, 'model:select', this.select.bind(this))
  this.listenTo(collection, 'model:deSelect', this.deSelect.bind(this))
  this.listenTo(
    this.selectedCollection,
    'add',
    this.onSelectionChange.bind(this)
  )
  this.listenTo(
    this.selectedCollection,
    'remove',
    this.onSelectionChange.bind(this)
  )
  this.listenTo(
    this.selectedCollection,
    'reset',
    this.onSelectionChange.bind(this)
  )

  this.$el.on('click', '.js-toolbar .js-new', this.emit.bind(this, 'createNew'))
  this.$el.on('click', '.js-select-all', this.selectAll.bind(this))
  this.$el.on('click', '.js-select-none', this.selectNone.bind(this))
  this.$el.on('click', '.js-toolbar .js-delete', this.handleDelete.bind(this))
  this.$el.on('click', '.js-more', this.emit.bind(this, 'loadMore'))
}

ListView.prototype = Object.create(View.prototype)

ListView.prototype.name = {
  singular: 'Item',
  plural: 'Items',
  resource: 'item'
}

ListView.prototype.ListItemView = ListItemView

ListView.prototype.template = template

// Compatibility with backbone admin
ListView.prototype.trigger = View.prototype.emit

ListView.prototype.displayFilterParams = function(params) {
  if (this.filters) this.filters.updateDisplay(params)
}

ListView.prototype.addListItem = function(model, index) {
  var listItem = new this.ListItemView(
    this.serviceLocator,
    model,
    index,
    this.collection.length
  )
  this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
  this.attachView(listItem)
  this.$el.find('.js-items').append(listItem.render().$el)
  return listItem
}

ListView.prototype.resetListItems = function(models, previousModels) {
  this.selectNone()
  previousModels.forEach(this.removeListItem.bind(this))
  models.forEach(this.addListItem.bind(this))
  window.scrollTo({ top: 0 })
}

ListView.prototype.removeListItem = function(model) {
  var view = this.getViewByModel(model)
  this.deSelect(model)
  if (!view) return
  view.remove()
}

ListView.prototype.createFilterView = function() {
  if (this.FilterView) {
    this.filters = new this.FilterView(this.serviceLocator, this)
    this.filters.on('filter', this.emit.bind(this, 'filter'))
    this.attachView(this.filters)
  }
}

ListView.prototype.onSelectionChange = function() {
  var count = this.selectedCollection.models.length
  this.$el
    .find('.js-selection-count')
    .text(count + (count === 1 ? ' item' : ' items'))
}

ListView.prototype.select = function(model) {
  this.selectedCollection.add(model)
}

ListView.prototype.deSelect = function(model) {
  this.selectedCollection.remove(model.id)
}

ListView.prototype.selectAll = function() {
  this.collection.models.forEach(function(model) {
    model.emit('select', model)
  })
}

ListView.prototype.selectNone = function() {
  var models = this.selectedCollection.models.slice(0)
  models.forEach(function(model) {
    model.emit('deSelect', model)
  })
}

ListView.prototype.handleDelete = function() {
  var length = this.selectedCollection.models.length
  if (!length) return
  modal({
    title: 'Delete',
    content:
      'Are you sure you want to delete ' +
      length +
      ' ' +
      (length === 1 ? 'item' : ' items') +
      '?',
    buttons: [
      { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
      { text: 'Delete', event: 'confirm', className: 'btn btn--action' }
    ]
  }).on(
    'confirm',
    function() {
      this.emit(
        'delete',
        this.selectedCollection.models.map(function(model) {
          return model.id
        })
      )
    }.bind(this)
  )
}

ListView.prototype.render = function() {
  // Render the template
  this.$el.append(
    this.template({
      name: this.name,
      allowed: this.serviceLocator.allowed,
      addVehicleEnabled:
        this.serviceLocator.config &&
        this.serviceLocator.config.enabledCmsFeatures &&
        this.serviceLocator.config.enabledCmsFeatures.includes('addVehicle'),
      importVehicleEnabled:
        this.serviceLocator.config &&
        this.serviceLocator.config.enabledCmsFeatures &&
        this.serviceLocator.config.enabledCmsFeatures.includes('importVehicle'),
      deleteEnabled:
        this.serviceLocator.config &&
        this.serviceLocator.config.enabledCmsFeatures &&
        this.serviceLocator.config.enabledCmsFeatures.includes('deleteVehicle'),
      archiveEnabled:
        this.serviceLocator.config &&
        this.serviceLocator.config.enabledCmsFeatures &&
        this.serviceLocator.config.enabledCmsFeatures.includes(
          'archiveOrPublishVehicle'
        ),
      exportEnabled:
        this.serviceLocator.config &&
        this.serviceLocator.config.enabledCmsFeatures &&
        this.serviceLocator.config.enabledCmsFeatures.includes('exportVehicle')
    })
  )
  this.collection.models.forEach(this.addListItem.bind(this))
  if (this.filters)
    this.$el.find('.js-filters').append(this.filters.render().$el)
  this.updatePagination()
  return this
}

ListView.prototype.loadData = function(cb) {
  cb()
}

module.exports = ListView
