const BasicToolbarView = require('../../../toolbar/views/form-toolbar')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, archiveOrPublishEnabled, data, saveEnabled, soldOrAvailableEnabled) {
buf.push("<div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button><div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\">Jump To\n <span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a href=\"#section-basic-details\">Basic Details</a></li><li><a href=\"#section-export-details\">Export Details</a></li><li><a href=\"#section-vehicle-details\">Vehicle Details</a></li><li><a href=\"#section-video\">Video</a></li><li><a href=\"#section-star-points\">Star Points</a></li><li><a href=\"#section-details\">Details</a></li><li><a href=\"#section-prices\">Pricing</a></li></ul></div></div></div><div class=\"control-group\">");
if ( allowed('usedVehicle', 'sell') && soldOrAvailableEnabled)
{
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn btn--warning js-sold-button\">" + (jade.escape(null == (jade_interp = !data.isSold ? 'Sold' : 'Set Available') ? "" : jade_interp)) + "</button></div>");
}
if ( data.state === 'Published' && !allowed('usedVehicle', 'publish'))
{
buf.push("<span class=\"label label--large\">You don't have permission to alter Published content.</span>");
}
else
{
buf.push("<div class=\"btn-group\">");
if ( saveEnabled)
{
buf.push("<button type=\"button\" class=\"btn js-btn-save\">Save</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><button type=\"button\" class=\"js-btn-save-and-close\">Save and Close</button></li></ul>");
}
buf.push("</div>");
if ( allowed('usedVehicle', 'publish') && archiveOrPublishEnabled)
{
if ( data.state === 'Published')
{
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn btn--warning js-btn-archive\">Archive</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--warning dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a href=\"#\" type=\"button\" class=\"js-btn-draft\">Revert to Draft</a></li></ul></div>");
}
else if ( data.state === 'Archived')
{
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn btn--warning js-btn-publish\">Republish</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--warning dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a href=\"#\" type=\"button\" class=\"js-btn-draft\">Revert to Draft</a></li></ul></div>");
}
else
{
buf.push("<button type=\"button\" class=\"btn btn--action js-btn-publish\">Publish</button>");
}
}
}
buf.push("</div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"archiveOrPublishEnabled" in locals_for_with?locals_for_with.archiveOrPublishEnabled:typeof archiveOrPublishEnabled!=="undefined"?archiveOrPublishEnabled:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"saveEnabled" in locals_for_with?locals_for_with.saveEnabled:typeof saveEnabled!=="undefined"?saveEnabled:undefined,"soldOrAvailableEnabled" in locals_for_with?locals_for_with.soldOrAvailableEnabled:typeof soldOrAvailableEnabled!=="undefined"?soldOrAvailableEnabled:undefined));;return buf.join("");
}
const modal = require('modal')

class ToolbarView extends BasicToolbarView {
  constructor(...args) {
    super(...args)
    this.$el.on(
      'click',
      '.js-btn-publish',
      this.disableAndEmit.bind(this, 'publish')
    )
    this.$el.on(
      'click',
      '.js-btn-draft',
      this.confirm.bind(this, 'Revert to Draft', 'draft')
    )
    this.$el.on(
      'click',
      '.js-btn-archive',
      this.confirm.bind(this, 'Archive Vehicle', 'archive')
    )
    this.$el.on(
      'click',
      '.js-sold-button',
      this.disableAndEmit.bind(this, 'sold')
    )
  }

  get template() {
    return template
  }

  disableAndEmit(eventName, e) {
    // disable the button
    e.target.disabled = true
    this.emit(eventName, e)
  }

  enableButtons() {
    this.$el.find('.js-btn-publish').prop('disabled', false)
    this.$el.find('.js-btn-draft').prop('disabled', false)
    this.$el.find('.js-btn-archive').prop('disabled', false)
    this.$el.find('.js-sold-button').prop('disabled', false)
  }

  confirm(action, type, e) {
    modal({
      title: action,
      content: 'This vehicle will be removed from public view. Are you sure?',
      buttons: [
        {
          text: 'Remain Published',
          event: 'cancel',
          className: 'btn',
          keyCodes: [27]
        },
        {
          text: action,
          event: 'confirm',
          className: 'btn btn--warning'
        }
      ]
    }).on('confirm', () => this.disableAndEmit(type, e))
  }
}

module.exports = ToolbarView
