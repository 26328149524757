const modal = require('modal')
const AssetCollection = require('../../asset/collections/asset')
const AssetPickerView = require('../../asset/views/picker')
const getImageFactory = require('../../asset/lib/image-factory')()
const WidgetAreaView = require('../../widget/views/widget-area')
const imageConfig = require('../../../../lib/image-config.json')
const {
  compareImages
} = require('../../../service/icc-images/lib/vehicle-updater')
// const blurImages = require('./lib/bulk-blur-delegate')
// const sendToDvi = require('./lib/send-dvi-delegate')

const createMultiImageInputs = () => ({
  setUploadTags(tags) {
    this.uploadTags = tags
  },

  handleAddImages(e) {
    e.preventDefault()
    const imageContext = e.target.dataset.context
    const collection = new AssetCollection()
    const assetListView = new AssetPickerView({
      collection: collection,
      type: 'image',
      serviceLocator: this.serviceLocator
    })
    collection.getByType('image')

    modal({
      title: 'Images',
      className: 'modal-asset-view wide',
      content: assetListView.$el,
      buttons: [
        { text: 'Cancel', event: 'Cancel', keyCodes: [27], className: 'btn' },
        {
          text: 'Add selected images',
          event: 'add',
          className: 'btn btn--action',
          keyCodes: [13]
        }
      ]
    })
      .on('add', () =>
        this.addImages(assetListView.selectedCollection, imageContext)
      )
      .on('close', () => assetListView.remove(assetListView))
  },

  // blurAll(e) {
  //   const imageContext = e.target.dataset.type

  //   let currentImages = this.model[imageContext].get('widgets') || []
  //   blurImages.call(this, currentImages, imageContext)
  // },

  // blurSelected(e) {
  //   const imageContext = e.target.dataset.type

  //   let currentImages = this.imageAreas[imageContext].selected
  //   blurImages.call(this, currentImages, imageContext)

  //   this.imageAreas[imageContext].selected = []
  // },

  // dviSelected(e) {
  //   const imageContext = e.target.dataset.type

  //   let currentImages = this.imageAreas[imageContext].selected
  //   sendToDvi.call(this, this.model.get('_id'), currentImages, imageContext)
  // },

  // dviSingle(model, imageContext) {
  //   sendToDvi.call(this, this.model.get('_id'), [model], imageContext)
  // },

  addImages(images, imageContext) {
    const ImageModel = getImageFactory('image').model
    let currentImages = this.model[imageContext].get('widgets') || []
    currentImages = currentImages.map(image => new ImageModel(image.toJSON()))

    let insertPos = currentImages.findIndex(img => img.version !== 'csv')

    insertPos = insertPos === -1 ? 0 : insertPos

    images.map(model => {
      const image = new ImageModel(model.attributes || model)
      const exists = currentImages.findIndex(currentImage => {
        return compareImages(image.toJSON(), currentImage.toJSON())
      })

      if (exists === -1) {
        currentImages.splice(insertPos, 0, image)
        insertPos++
      }

      if (exists >= 0) {
        currentImages[exists] = image
      }
    })

    this.model[imageContext].setWidgets(currentImages)

    // this.model[imageContext].setOrder(currentImages.map(image => image.cid))
  },

  removeImages(imageIds, imageContext) {
    const currentImages = this.model[imageContext].get('widgets') || []
    const filteredImages = currentImages.filter(
      ({ id }) => !imageIds.includes(id)
    )

    this.model[imageContext].setWidgets(filteredImages)
  },

  renderImageWidgetArea(imageContext, allowSelection) {
    this.imageAreas[imageContext] = new WidgetAreaView({
      model: this.model[imageContext],
      receiveDrops: false,
      serviceLocator: this.serviceLocator,
      widgetItemOptions: imageConfig,
      allowSelection,
      delayRender: true
    })

    // this.imageAreas[imageContext].on('addListeners', model => {
    //   model.on(
    //     'requestDviImage',
    //     this.dviSingle.bind(this, model, imageContext)
    //   )
    // })

    // this.imageAreas[imageContext].on('removeListeners', model => {
    //   model.off(
    //     'requestDviImage',
    //     this.dviSingle.bind(this, model, imageContext)
    //   )
    // })

    this.imageAreas[imageContext].beginRender()

    this.$el
      .find(`#field--${imageContext} .js-image-area`)
      .append(this.imageAreas[imageContext].$el)
  }
})

module.exports = createMultiImageInputs
