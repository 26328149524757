const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const compileJade = require('browjadify-compile')
const { join } = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (addVehicleEnabled, allowed, archiveEnabled, deleteEnabled, exportEnabled, importVehicleEnabled, name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"max-width js-toolbar\"><div class=\"toolbar__left\">");
if ( deleteEnabled)
{
buf.push("<button type=\"button\" class=\"btn js-delete\">Delete Selected</button>");
}
if ( archiveEnabled)
{
buf.push("<button type=\"button\" class=\"btn js-archive\">Archive Selected</button>");
}
if ( (allowed(name.resource, 'export')) && exportEnabled)
{
buf.push("<button type=\"button\" class=\"btn js-export\">Export Search</button>");
}
buf.push("</div>");
if ( (allowed(name.resource, 'create')) && importVehicleEnabled)
{
buf.push("<button type=\"button\" class=\"btn btn--action js-upload\">Upload Vehicles</button>");
}
if ( (allowed(name.resource, 'create')) && addVehicleEnabled)
{
buf.push("<a class=\"btn btn--action js-import\">Add Vehicle</a>");
}
buf.push("</div></div><div class=\"max-width\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"list--wide\"><div class=\"list\"><div class=\"list-container\"><div class=\"js-controls\"></div><div style=\"display: none\" class=\"notification notification--error js-import-error\"></div><div style=\"display: none\" class=\"notification notification--info js-import-progress\">Importing vehicle...</div><div class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div><div class=\"filters\"><div class=\"js-filters\"></div></div></div></div></div>");}.call(this,"addVehicleEnabled" in locals_for_with?locals_for_with.addVehicleEnabled:typeof addVehicleEnabled!=="undefined"?addVehicleEnabled:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"archiveEnabled" in locals_for_with?locals_for_with.archiveEnabled:typeof archiveEnabled!=="undefined"?archiveEnabled:undefined,"deleteEnabled" in locals_for_with?locals_for_with.deleteEnabled:typeof deleteEnabled!=="undefined"?deleteEnabled:undefined,"exportEnabled" in locals_for_with?locals_for_with.exportEnabled:typeof exportEnabled!=="undefined"?exportEnabled:undefined,"importVehicleEnabled" in locals_for_with?locals_for_with.importVehicleEnabled:typeof importVehicleEnabled!=="undefined"?importVehicleEnabled:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const modal = require('modal')
const ImportDialog = require('./import-dialog')
const importError = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (errorsToShow, undefined) {
buf.push("<strong></strong>There was a problem importing your vehicle<p>Please check below and try again.</p><ul>");
// iterate errorsToShow
;(function(){
  var $$obj = errorsToShow;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var error = $$obj[key];

if ( typeof error === 'string')
{
buf.push("<li>" + (jade.escape((jade_interp = error) == null ? '' : jade_interp)) + "</li>");
}
else
{
var errorArray = [] 
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var fieldKey = 0, $$l = $$obj.length; fieldKey < $$l; fieldKey++) {
      var fieldError = $$obj[fieldKey];

errorArray.push(fieldError) 
    }

  } else {
    var $$l = 0;
    for (var fieldKey in $$obj) {
      $$l++;      var fieldError = $$obj[fieldKey];

errorArray.push(fieldError) 
    }

  }
}).call(this);

buf.push("<li>Row " + (jade.escape((jade_interp = key) == null ? '' : jade_interp)) + ": " + (jade.escape((jade_interp = errorArray.join(', ')) == null ? '' : jade_interp)) + "</li>");
}
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var error = $$obj[key];

if ( typeof error === 'string')
{
buf.push("<li>" + (jade.escape((jade_interp = error) == null ? '' : jade_interp)) + "</li>");
}
else
{
var errorArray = [] 
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var fieldKey = 0, $$l = $$obj.length; fieldKey < $$l; fieldKey++) {
      var fieldError = $$obj[fieldKey];

errorArray.push(fieldError) 
    }

  } else {
    var $$l = 0;
    for (var fieldKey in $$obj) {
      $$l++;      var fieldError = $$obj[fieldKey];

errorArray.push(fieldError) 
    }

  }
}).call(this);

buf.push("<li>Row " + (jade.escape((jade_interp = key) == null ? '' : jade_interp)) + ": " + (jade.escape((jade_interp = errorArray.join(', ')) == null ? '' : jade_interp)) + "</li>");
}
    }

  }
}).call(this);

buf.push("</ul>");}.call(this,"errorsToShow" in locals_for_with?locals_for_with.errorsToShow:typeof errorsToShow!=="undefined"?errorsToShow:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const createAuthedHeaders = require('../../../../admin/source/js/lib/authed-headers')
const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')

class ListView extends BaseListView {
  constructor(
    serviceLocator,
    collection,
    paginationModel,
    dealerships,
    colours
  ) {
    super(...arguments)
    this.name = {
      singular: 'Used Vehicle',
      plural: 'Used Vehicles',
      resource: 'usedVehicle'
    }

    this.$el.on('click', '.js-import', this.showImportDialog.bind(this))
    this.$el.on('click', '.js-export', this.emit.bind(this, 'export'))
    this.$el.on(
      'click',
      '.js-toolbar .js-archive',
      this.handleArchive.bind(this)
    )
    this.$el.on('click', '.js-upload', this.uploadDialog.bind(this))

    this.dealerships = dealerships
    this.colours = colours
  }

  uploadDialog() {
    const url = '/used-vehicles/upload-file'

    const uppy = Uppy()
      .use(Dashboard, {
        closeAfterFinish: true
      })

      .use(XHRUpload, {
        endpoint: this.serviceLocator.config.apiUrl + url,
        headers: () => {
          const headers = createAuthedHeaders('POST', url, null, {
            headers: {},
            isForm: true
          })
          return headers
        },
        formData: true,
        getResponseError(responseText) {
          if (responseText === '') {
            return new Error('Failed to upload')
          }
          return new Error(JSON.parse(responseText).error)
        }
      })

    uppy.on('complete', () => {
      this.$el.find('.js-controls').append(`
        <div class="notification notification--info">
          File uploaded.
        </div>
      `)
      this.serviceLocator.usedVehicleService.emit('create')
    })

    uppy.getPlugin('Dashboard').openModal()
  }

  get template() {
    return template
  }

  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  addListItem(model, index) {
    const listItem = new this.ListItemView(
      this.serviceLocator,
      model,
      index,
      this.collection.length,
      this.dealerships,
      this.colours
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    try {
      this.$el.find('.js-items').append(listItem.render().$el)
    } catch (e) {
      this.serviceLocator.logger.error(e, 'Error rendering list item')
    }
    this.listenTo(
      listItem,
      'showRevisions',
      this.emit.bind(this, 'showRevisions', model)
    )
    this.listenTo(
      listItem,
      'showPriceHistory',
      this.emit.bind(this, 'showPriceHistory', model)
    )
    this.listenTo(
      listItem,
      'showReservationHistory',
      this.emit.bind(this, 'showReservationHistory', model)
    )
    this.listenTo(
      listItem,
      'duplicate',
      this.emit.bind(this, 'duplicate', model)
    )
    this.listenTo(
      listItem,
      'resendAutotraderImages',
      this.emit.bind(this, 'resendAutotraderImages', model)
    )
    this.listenTo(
      listItem,
      'showAvailabilityHistory',
      this.emit.bind(this, 'showAvailabilityHistory', model)
    )

    return listItem
  }

  showImportDialog() {
    const dialogView = new ImportDialog(this.serviceLocator).render()
    const errorMessage = this.$el.find('.js-import-error')
    const importMessage = this.$el.find('.js-import-progress')
    errorMessage.hide()

    modal({
      title: 'Import Vehicle',
      content: dialogView.$el,
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
        {
          text: 'New / Blank Vehicle',
          event: 'notKnown',
          className: 'btn'
        },
        {
          text: 'Import',
          event: 'import',
          className: 'btn btn--action',
          keyCodes: [13]
        }
      ]
    })
      .on('import', () => {
        importMessage.show()
        dialogView.getVehicleDetails()
      })
      .on('notKnown', () => {
        this.emit('createNew')
      })

    dialogView.on('vehicle', id => {
      importMessage.hide()
      this.emit('import')
      this.emit('edit', id)
    })

    dialogView.on('error', ({ message, errors = {} }) => {
      importMessage.hide()
      const errorsToShow = []
      if (errors.registration || errors.vin) {
        errorsToShow.push('This vehicle is already on our stock list')
      }

      if (errorsToShow.length) {
        errorMessage.html(
          importError({
            errorsToShow
          })
        )
      } else {
        errorMessage.text(message)
      }
      errorMessage.show()
    })
  }

  updateToolbar({ filter: { state } }) {
    const isArchivedOnly =
      state &&
      (state === 'Archived' ||
        (state.$in && state.$in.length === 1 && state.$in.includes('Archived')))

    this.$el.find('.js-archive').hide()

    this.$el.find('.js-delete').hide()

    if (
      this.serviceLocator.allowed('usedVehicle', 'delete') &&
      isArchivedOnly
    ) {
      this.$el.find('.js-delete').show()
    } else if (this.serviceLocator.allowed('usedVehicle', 'immediateDelete')) {
      this.$el.find('.js-delete').show()
    }
    if (
      this.serviceLocator.allowed('usedVehicle', 'archive') &&
      !isArchivedOnly
    ) {
      this.$el.find('.js-archive').show()
    }

    this.selectNone()
  }

  displayFilterParams(params) {
    super.displayFilterParams(params)
    this.updateToolbar(params)
  }

  createFilterView() {
    this.filters = new this.FilterView(this.serviceLocator, this)
    this.filters.on('filter', params => {
      this.updateToolbar(params)
      this.emit('filter', params)
    })
    this.attachView(this.filters)
  }

  handleArchive() {
    const length = this.selectedCollection.models.length
    if (!length) return
    modal({
      title: 'Archive',
      content:
        'Are you sure you want to archive ' +
        length +
        ' ' +
        (length === 1 ? 'item' : ' items') +
        '?',
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
        { text: 'Archive', event: 'confirm', className: 'btn btn--action' }
      ]
    }).on('confirm', () => {
      this.emit(
        'archive',
        this.selectedCollection.models.map(function(model) {
          return model.id
        })
      )
    })
  }
}

module.exports = ListView
