const moment = require('moment')
const schedule = require('regg')()
const visibility = require('regg')()

schedule

  .register('notScheduled', () => {
    return null
  })

  .register('expired', (liveDate, expiryDate) => {
    const description = 'Expired ' + moment(expiryDate).fromNow()
    return {
      labelClass: 'label-scheduled label--inverse',
      iconClass: 'icon--clock',
      title: 'Expired',
      description: description
    }
  })

  .register('scheduledFuture', liveDate => {
    const description = 'Goes live ' + moment(liveDate).fromNow()
    return {
      labelClass: 'label-scheduled label--notice',
      iconClass: 'icon--clock',
      title: 'Scheduled – Future',
      description: description
    }
  })

  .register('scheduledInDateWillExpire', (liveDate, expiryDate) => {
    const description = 'Expires ' + moment(expiryDate).fromNow()
    return {
      labelClass: 'label-scheduled label--success',
      iconClass: 'icon--clock',
      title: 'Scheduled – In date',
      description: description
    }
  })

  .register('scheduledInDateWillNotExpire', liveDate => {
    const description = 'Will not expire<br>Live ' + moment(liveDate).fromNow()
    return {
      labelClass: 'label-scheduled label--success',
      iconClass: 'icon--clock',
      title: 'Scheduled – In date',
      description: description
    }
  })

  .register('scheduledInDateExpiringSoon', (liveDate, expiryDate) => {
    const description = 'Expires ' + moment(expiryDate).fromNow()
    return {
      labelClass: 'label-scheduled label--warning',
      iconClass: 'icon--clock',
      title: 'Scheduled – Ending Soon',
      description: description
    }
  })

  .register('scheduledLiveSoonNotPublished', liveDate => {
    const description =
      'Goes live ' +
      moment(liveDate).fromNow() +
      ' but item is not yet published.'
    return {
      labelClass: 'label-scheduled label--error',
      iconClass: 'icon--clock',
      title: 'Scheduled – Unpublished Content',
      description: description
    }
  })

  .register('scheduledLiveNowNotPublished', liveDate => {
    let description
    if (!liveDate) {
      description =
        'Went live at time of writing, but item is not yet published.'
    } else {
      description =
        'Went live ' +
        moment(liveDate).fromNow() +
        ', but item is not yet published.'
    }
    return {
      labelClass: 'label-scheduled label--error',
      iconClass: 'icon--clock-important',
      title: 'Scheduled – Unpublished Content',
      description: description
    }
  })

visibility

  .register('draft', () => {
    return {
      labelClass: 'label-status',
      iconClass: 'icon--draft',
      title: 'Draft',
      description: 'This content is not published.'
    }
  })

  .register('published', () => {
    return {
      labelClass: 'label-status label--standard',
      iconClass: 'icon--published',
      title: 'Published',
      description: 'This content is able to send to AutoTrader.'
    }
  })

  .register('publishedHidden', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--published',
      title: 'Published – Hidden',
      description:
        'Published but not visible on the site due to schedule settings.'
    }
  })

  .register('archived', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--archived',
      title: 'Archived',
      description:
        'This content is no longer visible. Will not appear in unfiltered CMS searches'
    }
  })

module.exports = { visibility: visibility, schedule: schedule }
