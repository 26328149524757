const View = require('../views/overview')
const debug = require('../../../../admin/source/js/lib/debug')(
  'used-vehicles overview'
)
const createList = require('./list-view')
const createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')
const qs = require('querystring')

const asyncFind = service => (filter, sort, pagination) => {
  const func = (resolve, reject) => {
    service.find('', filter, sort, pagination, (err, res) => {
      if (err) return reject(err)

      resolve(res)
    })
  }
  return new Promise(func)
}

const embellishStats = (service, stats) => {
  const func = (resolve, reject) => {
    service.statsFind(stats, (err, res) => {
      if (err) return reject(err)

      resolve(res)
    })
  }
  return new Promise(func)
}

const getImageStat = async authedRequest => {
  return new Promise((resolve, reject) =>
    authedRequest('GET', '/icc-images/list-sets', null, (err, res, body) => {
      if (err) return reject(err)
      resolve({
        text: 'image sets not assigned to any vehicle',
        total: body.length,
        clickable: false
      })
    })
  )
}

const getNewImportStat = async (authedRequest, dealershipId) => {
  let url = '/new-vehicle-import/count?successful=false'
  if (dealershipId) url += `&dealership=${dealershipId}`

  return new Promise((resolve, reject) =>
    authedRequest('GET', url, null, (err, res, body) => {
      if (err) return reject(err)
      resolve({
        text: 'failed "New" Auto Trader vehicle imports',
        total: body,
        clickable: false
      })
    })
  )
}

const getScrapedVehicleStat = async (
  authedRequest,
  text,
  dealership,
  failures
) => {
  let url = `/scraped-vehicle/count?${qs.stringify({
    dealership,
    failures
  })}`

  return new Promise((resolve, reject) =>
    authedRequest('GET', url, null, (err, res, body) => {
      if (err) return reject(err)

      if (body === 0) {
        return resolve({
          text,
          total: body,
          clickable: false,
          hidden: true
        })
      }

      resolve({
        text,
        total: body,
        clickable: false,
        linkTo: failures
          ? `/vehicles/feed-failures/list?dealership=${dealership}`
          : ''
      })
    })
  )
}

const buildStat = (text, query, clickable = true) => ({
  text,
  query,
  clickable
})

const createStats = (query, type) => {
  const all = [
    buildStat('total vehicles', {
      state: { $ne: 'Archived' },
      ...query
    }),

    buildStat('published vehicles', {
      expiredDate: null,
      state: 'Published',
      ...query
    }),

    buildStat('vehicles sent to AutoTrader', {
      autoTraderStockId: { $ne: null },
      reservedDate: { $in: [null, false] },
      state: 'Published',
      isSold: { $in: [null, false] },
      ...query
    }),

    buildStat('vehicles with AutoTrader errors', {
      reservedDate: { $in: [null, false] },
      state: 'Published',
      isSold: { $in: [null, false] },

      $and: [
        { autoTraderError: { $exists: true } },
        { autoTraderError: { $ne: null } }
      ],
      ...query
    }),

    buildStat('vehicles advertised on AutoTrader', {
      autoTraderStockId: { $ne: null },
      reservedDate: { $in: [null, false] },
      state: 'Published',
      isSold: { $in: [null, false] },
      publishToAutoTrader: true,
      ...query
    }),

    buildStat('vehicles unadvertised on AutoTrader', {
      autoTraderStockId: { $ne: null },
      reservedDate: { $in: [null, false] },
      state: 'Published',
      isSold: { $in: [null, false] },
      publishToAutoTrader: false,
      ...query
    }),

    buildStat('not going to AutoTrader', {
      state: 'Published',
      $or: [
        { autoTraderStockId: null },
        { reservedDate: { $ne: null } },
        { expiredDate: { $ne: null } }
      ],
      ...query
    }),

    buildStat('sold vehicles', {
      state: 'Published',
      isSold: true,
      ...query
    }),

    buildStat('vehicles without attention grabber', {
      attentionGrabber: '',
      state: { $ne: 'Archived' },
      ...query
    }),

    buildStat('vehicles without interior colour', {
      interiorColour: null,
      state: { $ne: 'Archived' },
      ...query
    })
  ]

  const typeStats = {
    used: [
      buildStat('reserved vehicles', {
        state: 'Published',
        reservedDate: { $ne: null },
        ...query
      }),

      buildStat('unpublished vehicles', {
        expiredDate: null,
        state: 'Draft',
        ...query
      }),

      buildStat('expired but unarchived vehicles', {
        state: { $ne: 'Archived' },
        expiredDate: { $ne: null },
        ...query
      }),

      buildStat('archived vehicles', {
        state: 'Archived',
        ...query
      }),

      buildStat('vehicles with placeholder images', {
        'images.widgets.5': { $exists: false },
        state: { $ne: 'Archived' },
        ...query
      }),

      buildStat('published vehicles fully imaged', {
        'images.widgets.5': { $exists: true },
        expiredDate: null,
        state: 'Published',
        ...query
      }),

      buildStat('published vehicles with placeholder images', {
        expiredDate: null,
        state: 'Published',
        'images.widgets.5': { $exists: false },
        ...query
      }),

      buildStat('vehicles without images', {
        exteriorImages: { $size: 0 },
        interiorImages: { $size: 0 },
        images: { $size: 0 },
        state: { $ne: 'Archived' },
        ...query
      }),

      buildStat('vehicles without manufacturer colour', {
        manufacturerColour: null,
        state: { $ne: 'Archived' },
        ...query
      }),

      buildStat('vehicles without vat category', {
        vatCategory: null,
        state: { $ne: 'Archived' },
        ...query
      }),

      buildStat('vehicles published without starred features', {
        expiredDate: null,
        state: 'Published',
        features: { $size: 0 },
        ...query
      })
    ],
    new: [
      buildStat('vehicles awaiting images (sent to AutoWorks App)', {
        'images.widgets': { $size: 0 },
        state: { $ne: 'Archived' },
        ...query
      })
    ]
  }

  const typeSpecificStats = all.concat(typeStats[type])

  return typeSpecificStats
}

module.exports = serviceLocator => {
  const router = serviceLocator.router
  const getDealerships = asyncFind(serviceLocator.dealershipService)
  let currentFilter
  let currentTitle

  const authedRequest = createAuthedRequester(serviceLocator.config.apiUrl)

  const handleAllStats = async (dealerships, view, query, type) => {
    view.setAllStats([])
    const extraStats = {
      used: [
        await getImageStat(authedRequest),
        await getScrapedVehicleStat(authedRequest, 'Live Modix Vehicles'),
        await getScrapedVehicleStat(
          authedRequest,
          'Live Modix Vehicles with issues',
          null,
          true
        )
      ],
      new: [await getNewImportStat(authedRequest)]
    }

    let allStats = await embellishStats(
      serviceLocator.usedVehicleService,
      createStats(
        {
          dealership: { $in: dealerships.map(d => d._id) },
          ...query
        },
        type
      )
    )

    allStats = allStats.concat(extraStats[type])

    allStats = allStats.filter(stat => !stat.hidden)

    view.setAllStats(allStats)
  }

  const handleDealerStats = async (dealerships, view, query, type) => {
    view.setDealerships([])

    const withStats = await Promise.all(
      dealerships.map(async dealership => {
        const extraStats = {
          used: [
            await getScrapedVehicleStat(
              authedRequest,
              'Live Modix Vehicles',
              dealership._id
            ),
            await getScrapedVehicleStat(
              authedRequest,
              'Live Modix Vehicles with issues',
              dealership._id,
              true
            )
          ],
          new: [await getNewImportStat(authedRequest, dealership._id)]
        }

        dealership.stats = await embellishStats(
          serviceLocator.usedVehicleService,
          createStats({ dealership: dealership._id, ...query }, type)
        )

        dealership.stats = dealership.stats
          .concat(extraStats[type])
          .filter(stat => !stat.hidden)

        return dealership
      })
    )

    withStats.push({
      _id: 'none',
      shortName: 'Archive',
      stats: await embellishStats(serviceLocator.usedVehicleService, [
        buildStat('archived vehicles', {
          state: 'Archived',
          dealership: 'none',
          ...query
        })
      ])
    })

    const filterEmpty = withStats.filter(dealer => dealer.stats[0].total !== 0)

    view.setDealerships(filterEmpty)
  }

  const vehicleQueries = {
    used: {
      saleType: 'used'
    },
    new: {
      saleType: 'new'
    }
  }

  // Redirect the existing route to used-dashboard
  router.route('vehicles(/)', 'index', () => {
    if (serviceLocator.config.defaultSaleType === 'new') {
      router.navigate('vehicles/new-dashboard', {
        trigger: true,
        replace: true
      })
    } else {
      router.navigate('vehicles/used-dashboard', {
        trigger: true,
        replace: true
      })
    }
  })

  // New route for used-dashboard
  router.route('vehicles/used-dashboard(/)', 'usedDashboard', async () => {
    currentFilter = {}
    currentTitle = ''
    debug('admin used vehicles dashboard route triggered')

    const view = new View(serviceLocator, 'Used')
    router.render(view.render())

    const { results: dealerships } = await getDealerships(
      {},
      ['shortName', 'asc'],
      { pageSize: 50 }
    )

    handleAllStats(dealerships, view, vehicleQueries.used, 'used')
    handleDealerStats(dealerships, view, vehicleQueries.used, 'used')

    view.on('renderItems', (row, dealership) => {
      currentFilter = row.query
      currentTitle = `Viewing ${row.total} ${row.text} in ${dealership}`
      router.navigate('vehicles/overview-list', {
        trigger: true
      })
    })

    view.on('toggleType', type => {
      router.navigate(`vehicles/${type}-dashboard`, {
        trigger: true,
        replace: true
      })
    })
  })

  // New route for new-dashboard
  router.route('vehicles/new-dashboard(/)', 'newDashboard', async () => {
    currentFilter = {}
    currentTitle = ''
    debug('admin new vehicles dashboard route triggered')

    const view = new View(serviceLocator, 'New')
    router.render(view.render())

    const { results: dealerships } = await getDealerships(
      {},
      ['shortName', 'asc'],
      { pageSize: 50 }
    )

    handleAllStats(dealerships, view, vehicleQueries.new, 'new')
    handleDealerStats(dealerships, view, vehicleQueries.new, 'new')

    view.on('renderItems', (row, dealership) => {
      currentFilter = row.query
      currentTitle = `Viewing ${row.total} ${row.text} in ${dealership}`
      router.navigate('vehicles/overview-list', {
        trigger: true
      })
    })

    view.on('toggleType', type => {
      router.navigate(`vehicles/${type}-dashboard`, {
        trigger: true,
        replace: true
      })
    })
  })

  router.route('vehicles/overview-list(/)', 'list', async () => {
    if (!serviceLocator.allow('usedVehicle', 'discover')) return false

    const query = qs.parse(location.search.replace('?', ''))

    currentFilter = currentFilter || JSON.parse(query.filter)
    currentTitle = currentTitle || query.title

    if (!currentFilter && !currentTitle) {
      return router.navigate('vehicles/', { trigger: true })
    }

    const search = qs.stringify({
      filter: JSON.stringify(currentFilter),
      title: currentTitle
    })

    serviceLocator.router.navigate(`vehicles/overview-list?${search}`, {
      trigger: false,
      replace: true
    })

    const renderList = createList(serviceLocator, currentFilter, currentTitle)
    renderList((err, list) => {
      if (err) return
      router.render(list)
    })
  })
}
