const notify = require('../../notification/foreground')
const { getTenant } = require('../../account/lib/account-switch-handler')

module.exports = (serviceLocator, serviceName, name, baseRoute, FormView) => {
  const service = serviceLocator[serviceName]

  const setupNavigation = view => {
    view.on('close', () => {
      serviceLocator.router.navigate(baseRoute, { trigger: true })
    })

    view.on('saveComplete', isNew => {
      if (isNew)
        serviceLocator.router.navigate(`${baseRoute}/${view.model.id}/form`, {
          trigger: true
        })
    })

    view.on('back', () => {
      serviceLocator.router.navigate(baseRoute, { trigger: true })
    })
  }

  return (model, account, isNew) => {
    const form = new FormView(serviceLocator, model, account, isNew).render()
    serviceLocator.router.render(form, `${isNew ? 'New' : 'Edit'} ${name}`)

    const save = (icon, state, cb) => {
      const account = getTenant()
      const previousState = form.model.get('state') || 'Draft'
      let saveFn
      let id

      if (state) form.model.set('state', state)
      if (account) form.model.set('account', account)

      if (isNew && !form.model.get('_id')) {
        saveFn = service.create.bind(service, form.model.toJSON())
      } else {
        id = model.id || form.model.get('_id')
        saveFn = service.update.bind(service, id, form.model.toJSON())
      }

      afterSave(saveFn, err => {
        if (err) return form.model.set('state', previousState)
        notify(state || 'Saved', icon)
        if (typeof cb === 'function') cb(null, isNew)
      })
    }

    const afterSave = (saveFn, cb) => {
      saveFn((err, data) => {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.model.reset(data)
        form.clearUnsavedChanges()
        form.clearErrors()
        cb(null, form.model)
      })
    }

    form.on(
      'publish',
      save.bind(null, 'published', 'Published', form.close.bind(form))
    )
    form.on('draft', save.bind(null, 'draft', 'Draft', form.close.bind(form)))
    form.on('saveAndClose', save.bind(null, 'save', '', form.close.bind(form)))

    form.on('save', () => {
      save('save', null, (ignoreErr, isNew) => {
        form.emit('saveComplete', isNew)
      })
    })

    return setupNavigation(form)
  }
}
