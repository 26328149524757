module.exports = createController
const MenuBarView = require('../views/menu-bar')

const menu = [
  {
    title: 'Content',
    items: [
      { name: 'Sections', route: 'sections', resource: 'section' },
      { name: 'Articles', route: 'articles', resource: 'article' },
      { name: 'Case Studies', route: 'case-studies', resource: 'caseStudy' },
      { name: 'Authors', route: 'authors', resource: 'author' },
      { name: 'Lists', route: 'lists', resource: 'list' },
      { name: 'Redirects', route: 'redirects', resource: 'redirect' },
      { name: 'Tags', route: 'tags', resource: 'tag' },
      { name: 'FAQs', route: 'faqs', resource: 'faq' },
      {
        name: 'Review Entities',
        route: 'review-entities',
        resource: 'reviewEntities'
      }
    ]
  },
  {
    title: 'Product Management',
    items: [
      { name: 'Dealerships', route: 'dealerships', resource: 'dealership' },
      { name: 'Body Types', route: 'body-types', resource: 'bodyType' },
      { name: 'Makes', route: 'makes', resource: 'make' },
      { name: 'Models', route: 'models', resource: 'model' },
      {
        name: 'Image Set Configs',
        route: 'image-set-configs',
        resource: 'imageSetConfig'
      }
    ]
  },
  {
    title: 'Stock Management',
    items: [
      { name: 'Overview', route: 'vehicles', resource: 'usedVehicle' },
      {
        name: 'Vehicles',
        route: 'vehicles/list',
        resource: 'usedVehicle'
      },
      {
        name: 'Vehicle Collection',
        route: 'prep-form/form',
        resource: 'prep-form-entry'
      },
      {
        name: 'Features',
        route: 'features',
        resource: 'features'
      },
      {
        name: 'Standard Data',
        route: 'standard-data',
        resource: 'standardData'
      },
      { name: 'Colours', route: 'colours', resource: 'colour' },
      {
        name: 'Image Sets',
        route: 'image-sets',
        resource: 'imageSet'
      }
    ]
  },
  {
    title: 'User Management',
    items: [
      { name: 'Users', route: 'persons', resource: 'person' },
      { name: 'Careers', route: 'careers', resource: 'career' }
    ]
  },
  {
    title: 'Commercial',
    items: [
      { name: 'Offers', route: 'offers', resource: 'offer' },
      {
        name: 'Offer Categories',
        route: 'offer-categories',
        resource: 'offerCategory'
      },
      {
        name: 'Uploads',
        route: 'idealfile-upload',
        resource: 'idealfileUpload'
      },
      {
        name: 'Customers',
        route: 'customer',
        resource: 'customer'
      },
      {
        name: 'Bodyshop Submissions',
        route: 'bodyshop-submissions',
        resource: 'bodyshopSubmission'
      },
      {
        name: 'AutoTrader',
        route: 'autotrader',
        resource: 'autoTrader'
      }
    ]
  },
  {
    title: 'User Management',
    items: [
      { name: 'Roles', route: 'roles', resource: 'role' },
      {
        name: 'Administrators',
        route: 'administrators',
        resource: 'administrator'
      }
    ]
  },
  {
    title: 'Settings',
    items: [{ name: 'Accounts', route: 'accounts', resource: 'account' }]
  }
]

function createController(serviceLocator) {
  serviceLocator.hub.once('acl:load', function() {
    var menuBarView = new MenuBarView({
      el: $('.js-menu-bar'),
      menu: filterMenu(menu, serviceLocator),
      serviceLocator: serviceLocator
    })

    menuBarView.render()

    menuBarView.on('route', function(route) {
      serviceLocator.router.navigate(route, { trigger: true })
    })
  })
}

function filterMenu(menu, serviceLocator) {
  return menu.filter(function(section) {
    section.items = section.items.filter(function(menuItem) {
      const enabledForAccount = serviceLocator.config.enabledCmsSections
        ? serviceLocator.config.enabledCmsSections.includes(menuItem.name)
        : true

      return (
        ((menuItem && serviceLocator.allowed(menuItem.resource, 'discover')) ||
          !menuItem.resource) &&
        enabledForAccount
      )
    })
    return section.items.length > 0
  })
}
