const Collection = require('chale')
const MerstoneModel = require('merstone')
const Model = require('../models/used-vehicle')
const async = require('async')
const ListView = require('../views/list')
const pageSize = 100
const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')
const qs = require('querystring')
const notify = require('../../notification/foreground')

const createList = (serviceLocator, filter, title) => {
  const defaultCurrentParams = {
    keywords: '',
    filter: Object.assign({}, filter),
    sort: ['createdDate', 'desc']
  }

  const service = serviceLocator.usedVehicleService
  let currentParams = defaultCurrentParams

  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new MerstoneModel(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  const pagination = { page: currentPage, pageSize: pageSize }
  const base = 'vehicles'

  const getEntities = (keywords, filter, sort, pagination) => {
    service.find(keywords, filter, sort, pagination, (err, res) => {
      if (err)
        return serviceLocator.logger.error(err, `Could not load used vehicles`)
      collection.reset(
        res.results.map(entity => new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendEntities = (keywords, filter, sort, pagination) => {
    service.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(entity =>
        collection.add(new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  // Whenever an entiry is updated, reset the model with its new attributes
  service.on('update', function(id, attributes) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, ...attributes })
    service.cachedFind.clear()
  })

  service.on('partialUpdate', function(id, attributes) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, ...attributes })
    service.cachedFind.clear()
  })

  service.on('unreserve', function(id) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, reservedDate: null })
    service.cachedFind.clear()
  })

  service.on('reserve', function(id, reservedDate) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, reservedDate })
    service.cachedFind.clear()
  })

  const created = () => {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    service.cachedFind.clear()
    getEntities(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  }

  // Reload the first page of the current filters when a new item is created in case it should appear there
  service.removeAllListeners('create')
  service.removeAllListeners('import')
  service.on('create', created)
  service.on('import', created)

  const createList = ({ dealerships, colours }, cb) => {
    let list = new ListView(
      serviceLocator,
      collection,
      paginationModel,
      dealerships.results,
      colours.results
    )

    if (title) {
      list.name.plural = title
    }

    list = list.render()
    list.displayFilterParams(currentParams)

    list.on('createNew', function() {
      if (!serviceLocator.allow('usedVehicle', 'create')) return false
      serviceLocator.router.navigate(`${base}/form`, {
        trigger: true
      })
    })

    list.on('edit', function(id) {
      if (!serviceLocator.allow('usedVehicle', 'update')) return false
      serviceLocator.router.navigate(`${base}/${id}/form`, {
        trigger: true
      })
    })

    list.on('delete', function(ids) {
      if (!serviceLocator.allow('usedVehicle', 'delete')) return false
      async.each(ids, deleteOne, function(err) {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
        list.selectNone()
      })

      function deleteOne(id, cb) {
        service.delete(id, function(err) {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
    })

    list.on('archive', function(ids) {
      if (!serviceLocator.allow('usedVehicle', 'archive')) return false
      async.each(ids, archive, function(err) {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
        list.selectNone()
      })

      function archive(id, cb) {
        service.partialUpdate(
          id,
          { state: 'Archived', expiredDate: new Date() },
          function(err) {
            if (err) return cb(err)
            collection.remove(id)
            cb()
          }
        )
      }
    })

    list.on('filter', function(params) {
      currentParams = params
      currentPage = 1
      const pagination = { page: currentPage, pageSize: pageSize }
      params.filter = Object.assign({}, filter, params.filter)

      const query = {
        ...params
      }

      if (params.filter.state) {
        query.state = params.filter.state.$in
      }

      if (params.filter.dealership) {
        query.dealership = params.filter.dealership
      }

      const imagesFilter = params.filter['images.widgets.5']
      if (imagesFilter) {
        if (imagesFilter.$exists) {
          query.imaged = true
        } else {
          query.placeholder = true
        }
      }

      if (params.filter['exteriorImages']) {
        query['360Exterior'] = true
      }

      if (params.filter['interiorImages.widgets']) {
        query['360Interior'] = true
      }

      if (params.filter.saleType.$in) {
        query.saleType = 'all'
      } else if (params.filter.saleType) {
        query.saleType = params.filter.saleType
      }

      if (params.filter.autoTraderStockId) {
        if (params.filter.autoTraderStockId.$ne === null) {
          query['autoTraderStatus'] = 'published'
        } else {
          query['autoTraderStatus'] = 'unpublished'
        }
      }

      delete query.filter

      const search = qs.stringify(query)

      serviceLocator.router.navigate(`${base}/list?${search}`, {
        trigger: false,
        replace: true
      })

      getEntities(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', function() {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendEntities(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.on('showRevisions', model => {
      serviceLocator.router.navigate(`${base}/` + model.id + '/revisions', {
        trigger: true
      })
    })

    list.on('showPriceHistory', model => {
      serviceLocator.router.navigate(
        `${base}/` + model.id + '/pricing-history',
        {
          trigger: true
        }
      )
    })

    list.on('showReservationHistory', model => {
      serviceLocator.router.navigate(
        `${base}/` + model.id + '/reservation-history',
        {
          trigger: true
        }
      )
    })

    list.on('duplicate', model => {
      serviceLocator.router.navigate(`${base}/` + model.id + '/duplicate', {
        trigger: true
      })
    })

    list.on('resendAutotraderImages', model => {
      serviceLocator.usedVehicleService.resendAutotraderImages(
        model.id,
        err => {
          if (err) return alert(err.message)

          notify('Images Resent', 'save')
        }
      )
    })

    list.on('showAvailabilityHistory', model => {
      serviceLocator.router.navigate(
        `${base}/` + model.id + '/availability-history',
        {
          trigger: true
        }
      )
    })

    list.on('export', ids => {
      const query =
        ids && ids.length
          ? qs.stringify({ ids })
          : qs.stringify({
              keywords: currentParams.keywords,
              filter: JSON.stringify(currentParams.filter)
            })

      const url = '/api/used-vehicles/export?' + query
      window.location = createSignedApiUrl(
        url,
        window.localStorage.apiKey,
        window.localStorage.apiId,
        window.config.apiUrl
      )
    })

    cb(null, list)
  }

  const render = cb => {
    if (location.search) {
      const query = qs.parse(location.search.replace('?', ''))

      currentParams = Object.assign({}, defaultCurrentParams, {
        keywords: query.keywords || '',
        sort: query.sort,
        filter: { ...defaultCurrentParams.filter }
      })

      if (query.dealership) {
        currentParams.filter.dealership = query.dealership
      }

      if (query.placeholder) {
        currentParams.filter['images.widgets.5'] = { $exists: false }
      }

      if (query.imaged) {
        currentParams.filter['images.widgets.5'] = { $exists: true }
      }

      if (query['360Exterior']) {
        currentParams.filter['exteriorImages'] = { $gt: { $size: 0 } }
      }

      if (query['360Interior']) {
        currentParams.filter['interiorImages.widgets'] = { $gt: { $size: 0 } }
      }

      if (query.autoTraderStatus) {
        if (query.autoTraderStatus === 'published') {
          currentParams.filter.autoTraderStockId = { $ne: null }
        } else {
          currentParams.filter.autoTraderStockId = { $eq: null }
        }
      }

      if (query.state) {
        if (Array.isArray(query.state)) {
          currentParams.filter.state = { $in: query.state }
        } else {
          currentParams.filter.state = query.state
        }
      }

      if (query.saleType && query.saleType !== 'all') {
        currentParams.filter.saleType = query.saleType
      } else if (query.saleType === 'all') {
        currentParams.filter.saleType = { $in: ['new', 'used'] }
      }
    }

    getEntities(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    async.parallel(
      {
        dealerships: callback =>
          serviceLocator.dealershipService.cachedFind(
            '',
            {},
            [],
            { page: 1, pageSize: 500 },
            callback
          ),
        colours: callback =>
          serviceLocator.colourService.cachedFind(
            '',
            { type: 'base' },
            [],
            { page: 1, pageSize: 500 },
            callback
          )
      },
      (err, data) => {
        if (err) {
          alert(err.message)
          return cb(err)
        }

        createList(data, cb)
      }
    )
  }

  return render
}

module.exports = createList
