const schemata = require('schemata')

const featureSetSchema = () =>
  schemata({
    name: 'Feature Set',
    properties: {
      label: {
        type: String
      },
      value: {
        type: String
      },
      starPosition: {
        type: Number
      }
    }
  })

module.exports = featureSetSchema
