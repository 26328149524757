const { promisify } = require('util')

function compareImages(current, image) {
  if (current.name && image.name) {
    return current.name === image.name
  }

  return current.binaryUri === image.binaryUri
}

function createVehicleUpdater(serviceLocator, service, account) {
  const { getImageSetForVehicle, uploadSet } = service

  const processVehicle = async vehicle => {
    const imageSet = await getImageSetForVehicle(
      vehicle.vin,
      vehicle.registration
    )

    if (!imageSet) return

    serviceLocator.logger.info(
      `Found images for ${vehicle.registration || vehicle.vin}`,
      imageSet
    )

    const images = await uploadSet(
      imageSet,
      vehicle.registration || vehicle.vin
    )

    const newImages = {}

    Object.keys(images).forEach(key => {
      const currentImages = (vehicle[key] && vehicle[key].widgets) || []
      let insertPos = currentImages.findIndex(img => img.version !== 'csv')

      insertPos = insertPos === -1 ? 0 : insertPos

      images[key].forEach(image => {
        const exists = currentImages.findIndex(currentImage => {
          return compareImages(currentImage, image)
        })

        if (exists === -1) {
          currentImages.splice(insertPos, 0, image)
          insertPos++
        }

        if (exists >= 0) {
          currentImages[exists] = image
        }
      })

      newImages[key] =
        key === 'exteriorImages' ? currentImages : { widgets: currentImages }
    })

    const updated = await promisify(
      serviceLocator.usedVehicleService.partialUpdate
    )(
      {
        _id: vehicle._id,
        needsIccUpdate: false,
        account,
        ...newImages
      },
      { validate: 'service' }
    )

    serviceLocator.logger.info(`Updated ${vehicle.registration}`)
    await serviceLocator.usedVehicleService.resendAutotraderImages({
      vehicleId: vehicle._id
    })

    await serviceLocator.usedVehicleService.createSystemRevision(updated)
  }

  return processVehicle
}

module.exports = createVehicleUpdater
module.exports.compareImages = compareImages
