const schemata = require('schemata')
const required = require('validity-required')
const resolveImages = require('../../../lib/image-resolver')
const fs = require('fs')
const path = require('path')

const accountsDir = path.join(__dirname, '../../../config')

const slugValidator = (prop, name, object) => {
  const slug = object[prop]

  if (!slug) {
    return 'This field is required'
  }

  // find slug in config folder
  const accountFiles = fs.readdirSync(accountsDir)
  const accountFile = accountFiles.find(file => file.endsWith(`${slug}.json`))

  if (!accountFile) {
    return 'This slug is not valid for any account'
  }
}

module.exports = findOne =>
  schemata({
    name: 'Account',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: [required]
      },
      configSlug: {
        type: String,
        validators: [required, slugValidator]
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      autoTraderImages: {
        type: Object,
        defaultValue: () => [],
        resolve: account => resolveImages(account.autoTraderImages),
        resolveType: Array
      },
      autoTraderPlaceholderImages: {
        type: Object,
        defaultValue: () => [],
        resolve: account => resolveImages(account.autoTraderPlaceholderImages),
        resolveType: Array
      },
      replaceNewStock: {
        type: Boolean,
        defaultValue: false
      },
      replaceUsedStock: {
        type: Boolean,
        defaultValue: false
      },
      youtubeRefreshToken: {
        type: String
      }
    }
  })
