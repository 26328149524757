const visibility = require('regg')()

visibility

  .register('draft', () => {
    return {
      labelClass: 'label-status',
      iconClass: 'icon--draft',
      title: 'Draft',
      description: 'This vehicle is not published.'
    }
  })

  .register('published', () => {
    return {
      labelClass: 'label-status label--standard',
      iconClass: 'icon--published',
      title: 'Published',
      description: 'This vehicle is now able to go to AutoTrader.'
    }
  })

  .register('sold', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--published',
      title: 'Sold',
      description: `It will also no longer be sent to AutoTrader<br>
        After 30 days, it will be automatically archived.`
    }
  })

  .register('soldArchived', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--archived',
      title: 'Sold and Archived',
      description: `It will also no longer be sent to AutoTrader`
    }
  })

  .register('archived', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--archived',
      title: 'Archived',
      description: `It will also no longer be sent to AutoTrader`
    }
  })

module.exports = { visibility }
