module.exports = { isAuthed: isAuthed, logout: logout }

const sign = require('cf-signature')
const url = require('url')
const {
  getTenant
} = require('../../../../components/admin/account/lib/account-switch-handler')

function isAuthed() {
  return (
    !!window.localStorage.getItem('apiKey') &&
    !!window.localStorage.getItem('apiId')
  )
}

$(document).ajaxError(function(event, jqXHR) {
  // If you get an unauthenticated
  if (jqXHR.status === 401) return logout('auth')
})

function logout(reason) {
  window.localStorage.removeItem('apiKey')
  window.localStorage.removeItem('apiTimeout')
  window.localStorage.removeItem('aclRoles')
  document.location = '/login?reason=' + (reason || '')
}

window.Backbone.ajax = function(request) {
  // This will be set on /auth
  const key = window.localStorage.getItem('apiKey')
  const id = window.localStorage.getItem('apiId')
  let sessionTimeout = window.localStorage.getItem('apiTimeout')

  // Set a 24 hour session timeout. After which point you have to login again
  if (sessionTimeout !== null && sessionTimeout < Date.now())
    return logout('timeout')

  if (!isAuthed()) return logout()

  sessionTimeout = Date.now() + 24 * 60 * 1000 * 60
  window.localStorage.setItem('apiTimeout', sessionTimeout)

  /* eslint-disable one-var */
  var path = url.parse(request.url).path,
    contentType = request.contentType ? request.contentType : '',
    date = new Date().toUTCString(),
    querystring

  if (request.data) {
    // $.param does not escape `'` characters from querystrings. It only uses
    // `encodeURIComponent` under the hood which correctly does not encode them.
    // Characters that the RFC dictates should not be encoded: http://goo.gl/0La9hO
    // Node's url parser always escapes these characters anyway http://goo.gl/F472UF
    //
    // This inconsistency results in differences in the URL which returns a 401
    // and logs out the admin user. So here we're manually replacing `'` chars with
    // the escaped version
    querystring = $.param(request.data)
    querystring = querystring.replace(/'/g, window.escape("'"))
  }

  // Need to add the query string parameters to the url, because the are currently in
  // the form of an object at request.data, and jQuery adds to the url later on
  if (request.type === 'GET' && querystring) path += '?' + querystring

  var signature = sign(key, request.type, contentType, date, path)
  request.headers = {
    Authorization: 'Catfish ' + id + ':' + signature,
    'x-cf-date': date,
    'x-cf-tenant': getTenant()
  }

  $.ajax(request)
}
