const required = require('validity-required')
const schemata = require('schemata')
const resolveImages = require('../../../lib/image-resolver')

module.exports = () => {
  const schema = schemata({
    name: 'Dealership',
    properties: {
      _id: { type: String },
      account: { type: String, validators: [required] },
      location: { type: String, validators: [required] },
      shortName: { type: String, validators: [required] },
      addressLine1: {
        type: String,
        validators: [required]
      },
      addressLine2: { type: String },
      addressLine3: { type: String },
      town: {
        type: String,
        validators: [required]
      },
      postcode: {
        type: String,
        validators: [required]
      },
      createdDate: { type: Date, defaultValue: () => new Date() },
      marketingImages: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.marketingImages),
        resolveType: Array
      },
      videoMarketLeadingHeadline: { type: String },
      videoMarketLeadingPoints: { type: String },
      videoMapImage: {
        type: Object,
        defaultValue: () => [],
        resolve: async dealership => resolveImages(dealership.videoMapImage),
        resolveType: Array
      },
      autoTraderFeedId: { type: String },
      autoTraderUrl: { type: String },
      manufacturerUsedUrl: { type: String },
      disableVehicleAllocating: { type: Boolean, defaultValue: () => false },
      autoTraderSlots: { type: Number }
    }
  })

  return schema
}
