const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (dealership, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};































jade_mixins["multilineField"] = jade_interp = function(property, label, description, value, required, rows){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</span><textarea" + (jade.attr("name", property, true, false)) + (jade.attr("rows", rows || 5, true, false)) + " class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div></div>");
};
buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('location', 'Name', 'Full name of dealership', dealership.location, true, 'text');
jade_mixins["inputField"]('shortName', 'Short Name', 'Shorter name to describe dealership', dealership.shortName, true, 'text');
buf.push("</div></div>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};































jade_mixins["multilineField"] = jade_interp = function(property, label, description, value, required, rows){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</span><textarea" + (jade.attr("name", property, true, false)) + (jade.attr("rows", rows || 5, true, false)) + " class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div></div>");
};
buf.push("<div id=\"section-export-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Export Details</h2></div><div class=\"panel-content\"><h3>AutoTrader</h3>");
jade_mixins["inputField"]('autoTraderFeedId', 'AutoTrader Feed ID', ' ID for AutoTrader vehicle feeds', dealership.autoTraderFeedId, false, 'text');
jade_mixins["inputField"]('autoTraderSlots', 'AutoTrader Slots', 'Number of slots available on autotrader.co.uk for this dealership', dealership.autoTraderSlots, false, 'text');
buf.push("</div></div>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<div id=\"section-location-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Location and Contact Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('addressLine1', 'Address Line 1', '', dealership.addressLine1, true, 'text');
jade_mixins["inputField"]('addressLine2', 'Address Line 2', '', dealership.addressLine2, false, 'text');
jade_mixins["inputField"]('addressLine3', 'Address Line 3', '', dealership.addressLine3, false, 'text');
jade_mixins["inputField"]('town', 'Town', '', dealership.town, true, 'text');
jade_mixins["inputField"]('postcode', 'Postcode', '', dealership.postcode, true, 'text');
buf.push("</div></div><div id=\"section-marketing-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Marketing Images</h2><p>These are sent with vehicles to third-parties</p></div><div class=\"panel-content\"><div id=\"field--marketingImages\" data-field=\"marketingImages\" class=\"form-row form-row-full-width\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"js-error\"></div></div><div class=\"form-row form-row-actions\"><input value=\"Add Images\" data-context=\"marketingImages\" class=\"btn btn--success js-images-add\"/></div></div></div>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};































jade_mixins["multilineField"] = jade_interp = function(property, label, description, value, required, rows){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</span><textarea" + (jade.attr("name", property, true, false)) + (jade.attr("rows", rows || 5, true, false)) + " class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div></div>");
};
buf.push("<div id=\"section-video\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Video</h2><div class=\"form-copy\"><p>These fields affect vehicle videos generated for this dealership.</p></div></div><div class=\"panel-content\"><div class=\"form-copy\"><p>This image must be square, at least 500x500 pixels.</p><p>The dealership MUST be located at the exact centre as this is where a marker will appear.</p></div><div id=\"field--videoMapImage\" data-field=\"videoMapImage\" class=\"form-row form-row-full-width\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"js-error\"></div></div><div class=\"form-row form-row-actions\"><input value=\"Add Image\" data-context=\"videoMapImage\" class=\"btn btn--success js-images-add\"/></div>");
jade_mixins["inputField"]('videoMarketLeadingHeadline', 'Market Leading Headline', 'Shown under the text "Market Leading"', dealership.videoMarketLeadingHeadline, false, 'text');
jade_mixins["multilineField"]('videoMarketLeadingPoints', 'Market Leading Points', 'Each newline will be an individual bullet point under the above header.', dealership.videoMarketLeadingPoints, false, 5);
buf.push("</div></div></form></div></div>");}.call(this,"dealership" in locals_for_with?locals_for_with.dealership:typeof dealership!=="undefined"?dealership:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const createMultiImageViewFunctions = require('../../lib/multi-image-view')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args

    this.account = account
    this.$el.on('click', '.js-images-add', e => this.handleAddImages(e))
    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )

    Object.assign(this, createMultiImageViewFunctions())
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Dealership' : 'Edit Dealership',
        dealership: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
    this.renderImageWidgetArea('marketingImages')
    this.renderImageWidgetArea('videoMapImage')

    return this
  }
}

module.exports = FormView
