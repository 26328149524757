const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (colour, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");














buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", colour.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The Name should not include the Finish string, just set the Finish and it will be added for you.</p></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Type</span><select name=\"type\" class=\"control control--choice form-field js-type\"><option value=\"\"" + (jade.attr("selected", colour.type === '', true, false)) + ">-- Select a Type --</option><option value=\"base\"" + (jade.attr("selected", colour.type === 'base', true, false)) + ">Base Colour</option><option value=\"manufacturer\"" + (jade.attr("selected", colour.type === 'manufacturer', true, false)) + ">Manufacturer Colour</option></select></label><div class=\"js-error\"></div></div><div id=\"field--parent\" data-field=\"parent\" class=\"form-row\"><label><span class=\"form-label-text\">Parent</span><div class=\"form-field js-parent\"></div></label><div class=\"js-error\"></div></div></div></div></form></div></div>");}.call(this,"colour" in locals_for_with?locals_for_with.colour:typeof colour!=="undefined"?colour:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const BaseSelect = require('../../base/views/select')
const ToolbarView = require('./form-toolbar')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
    this.$el.on('change', '.js-type', this.handleTypeChange.bind(this))
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Colour' : 'Edit Colour',
        colour: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    this.renderParentSelect()

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }

  setupToolbar() {
    this.toolbar = new ToolbarView(this.serviceLocator, this.model, this)
    this.toolbar.on('back', this.emit.bind(this, 'back'))
    this.toolbar.on('save', this.emit.bind(this, 'save'))
    this.toolbar.on('saveAndClose', this.emit.bind(this, 'saveAndClose'))
  }

  handleTypeChange(e) {
    const finishSelect = this.$el.find('.js-finish')

    if (e.target.value === 'manufacturer') {
      finishSelect.removeClass('hidden')
    } else {
      finishSelect.addClass('hidden')
    }
  }

  renderParentSelect() {
    this.parentSelect = new BaseSelect(this.serviceLocator, {
      service: 'colourService',
      selected: this.model.get('parent'),
      limitToOneValue: true,
      singular: 'colour',
      plural: 'colours',
      textProperty: 'name',
      sortProperty: 'name',
      filter: { type: 'base' }
    })

    this.$el.find('.js-parent').append(this.parentSelect.render().$el)

    this.parentSelect.on('change', colour => {
      this.model.set('parent', colour)
    })
  }
}

module.exports = FormView
