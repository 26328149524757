const View = require('ventnor')
const defaultSelectizeConfig = {
  delimiter: ',',
  create: false,
  preload: true
}

class BaseSelectView extends View {
  constructor(serviceLocator, options) {
    let { selected } = options
    if (!Array.isArray(selected)) selected = [selected]
    super(serviceLocator, selected)
    const {
      service,
      limitToOneValue,
      singular,
      plural,
      textProperty,
      sortProperty,
      sortDirection = 'asc',
      selectizeConfig,
      filter,
      displayAllOption,
      displayNoneOption,
      displayNoneLabel = 'Undesignated',
      maxEntries
    } = options

    this.service = service
    this.singular = singular
    this.plural = plural
    this.textProperty = textProperty
    this.sortProperty = sortProperty
    this.sortDirection = sortDirection
    this.selectizeConfig = selectizeConfig || defaultSelectizeConfig
    this.filter = filter || {}
    this.displayAllOption = displayAllOption
    this.displayNoneOption = displayNoneOption
    this.allOption = { value: 'all', _id: 'all', [this.textProperty]: 'All' }
    this.noneOption = {
      value: 'none',
      _id: 'none',
      [this.textProperty]: displayNoneLabel
    }
    this.maxEntries = maxEntries || 500

    this.$el = $(limitToOneValue ? `<select />` : '<select multiple />')
    this.$el.attr('name', plural)
    this.$el.attr(
      'placeholder',
      limitToOneValue ? `Choose an ${singular}` : `Choose one or more ${plural}`
    )
    this.$el.addClass('control control--choice')
    if (!limitToOneValue) this.$el.addClass('control--multiline')

    this.mapResult = options.mapResult || false

    this.el = this.$el[0]
    this.selected = selected || []
    this.setFilter = this.setFilter
  }

  initializeSelectize() {
    const pagination = { page: 1, pageSize: this.maxEntries }

    const allOptionIndex = this.selected.indexOf('all')
    const allSelected = [...this.selected]
    if (allOptionIndex > -1) this.selected.splice(allOptionIndex, 1)

    const noneOptionIndex = this.selected.indexOf('none')
    if (noneOptionIndex > -1) this.selected.splice(noneOptionIndex, 1)

    const query = Object.assign({ _id: { $in: this.selected } }, this.filter)
    this.serviceLocator[this.service].find(
      '',
      query,
      [],
      pagination,
      (err, res) => {
        if (err) return alert(`Cannot find existing ${this.singular}`)

        if (allOptionIndex > -1) res.results.unshift(this.allOption)
        if (noneOptionIndex > -1) res.results.unshift(this.noneOption)

        const index = res.results.reduce((index, res) => {
          index[res._id] = res
          return index
        }, {})

        // This ensure they stay in the same order
        allSelected.forEach(id => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          let text
          if (typeof this.textProperty === 'function') {
            text = this.textProperty(index[id])
          } else {
            text = index[id] ? index[id][this.textProperty] : id
          }
          this.el.selectize.addOption({
            value: id,
            text
          })
          // Select the added option
          this.el.selectize.addItem(id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  setFilter(filter) {
    this.filter = filter
    // Trigger refresh
    if (this.selectize) {
      this.selectize.clearOptions()
      this.selectize.onSearchChange('')
    }
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    const pagination = { page: 1, pageSize: this.maxEntries }
    this.serviceLocator[this.service].cachedFind(
      query,
      this.filter,
      [this.sortProperty, this.sortDirection],
      pagination,
      (err, data) => {
        if (err) return cb(err)
        if (this.displayAllOption) data.results.unshift(this.allOption)
        if (this.displayNoneOption) data.results.unshift(this.noneOption)
        if (this.mapResult) {
          cb(data.results.map(this.mapResult))
        } else {
          cb(
            data.results.map(res => {
              let text
              if (typeof this.textProperty === 'function') {
                text = this.textProperty(res)
              } else {
                text = res[this.textProperty]
              }
              return { value: res._id, text }
            })
          )
        }
      }
    )
  }

  render() {
    const selectizeSettings = Object.assign(
      {},
      defaultSelectizeConfig,
      this.selectizeConfig,
      {
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this)
      }
    )

    setTimeout(() => {
      this.$el.selectize(selectizeSettings)
      this.selectize = this.el.selectize
    }, 0)
    return this
  }
}

module.exports = BaseSelectView
