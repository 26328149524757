export const defaultFinance = {
  monthlyPayment: null,
  carPrice: null,
  depositAmount: null,
  adminFee: null,
  balanceToFinance: null,
  firstPayment: null,
  numberOfPayments: null,
  finalPayment: null,
  amountPayable: null,
  interestCharges: null,
  interestApr: null,
  interestRate: null,
  optionFee: null,
  lender: null
}

export const financeProperties = Object.keys(defaultFinance)

export default () => async () => {
  return {
    hpFinance: { ...defaultFinance },
    pcpFinance: { ...defaultFinance }
  }
}
